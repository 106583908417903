import { createTheme } from '@material-ui/core/styles';

export const defaultThemeOverrides = {
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
  spacing: 4,
  breakpoints: {
    values: {
      xs: 375,
      sm: 576,
      md: 768,
      lg: 1025,
      xl: 1200,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 14.8,
  },
  button: {
    size: {
      large: {
        height: '4rem',
      },
      small: {
        height: '2.4rem',
      },
      medium: {
        height: '3.2rem',
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
    button: {
      textTransform: 'none',
    },
    htmlFontSize: 10,
    allVariants: {
      color: '#373637',
    },
  },
  familyOfFont: {
    primary: 'Roboto',
  },
  palette: {
    // TODO: boxShadowColors to be removed, since it is not in gm style guide, until it is fixed where used
    boxShadowColors: {
      boxShadow1: 'rgba(54, 82, 107, 0.12)',
      boxShadow2: 'rgba(31, 63, 77, 0.08)',
      boxShadow3: 'rgba(0, 0, 0, 0.12)',
      boxShadow4: 'rgba(101, 101, 101, 0.08)',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    primary: {
      main: '#0079BF', // old value: '#0058B3',
      grey: '#373637',
      white: '#FFFFFF',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    typoColor: {
      primary: '#373637',
      secondary: '#5F5E5F',
      disabled: '#AFAFAF',
      white: '#FFFFFF',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    greyDark: {
      shade1: '#9B9A9B',
      shade2: '#878687',
      shade3: '#5F5E5F',
      shade4: '#373637',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    greyLight: {
      shade1: '#F7F7F7',
      shade2: '#E1E1E1',
      shade3: '#D7D7D7',
      shade4: '#C3C3C3',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    borders: {
      body: '#D7D7D7',
      input: '#5F5E5F',
      inputHover: '#373637',
      white: '#FFFFFF',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    actions: {
      primary: '#0079BF',
      primaryHover: '#0693E4',
      secondary: '#373637',
      link: '#139DED',
      info: '#5F5E5F',
      light: '#0058B3',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    error: {
      main: '#BB0A15',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    warning: {
      main: '#FF8139',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    success: {
      main: '#4CAE29',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    background: {
      white: '#FFFFFF',
      light: '#F7F7F7',
      dark: '#202020',
      transparent: 'transparent',
      none: 'none',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    overlays: {
      light: 'rgba(255, 255, 255, 0.4)',
      dark: 'rgba(55, 54, 55, 0.4)',
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    pastolsBackground: {
      accent1: '#FFE4E4', // red
      accent2: '#FEFCBF', // yellow
      accent3: '#D1FFE0', // green
      accent4: '#DCE6F3', // blue
      accent5: '#F2E7FF', // purple
      accent6: '#FFE5D6', // orange
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    pastolsForeground: {
      accent1: '#AD1E1E', // red
      accent2: '#B7791F', // yellow
      accent3: '#22744B', // green
      accent4: '#003F80', // blue
      accent5: '#6B46C1', // purple
      accent6: '#FF7B32', // orange
    },
    // PLease do not add any new color code that is not in the style guide provided by designers - contact Archana
    gradient: {
      purpleLove:
        'linear-gradient(rgba(68, 16, 102, 1.0) 0%, rgba(247, 0, 104, 1.0) 100%)',
    },
    icon: {
      shade1: '#373637',
    },
    components: {
    MuiUseMediaQuery: {
      defaultProps: {
          noSsr: true,
      },
    },
  }
  },
};

// Create a theme instance
export const defaultTheme = createTheme(defaultThemeOverrides);
