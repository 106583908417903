import cx from 'classnames';

import _map from 'lodash/map';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import ContentWrapper from '../../components/molecules/ContentWrapper';
import Content from '../../components/molecules/Content';
import HeroBanner from '../../components/molecules/HeroBanner';

import { CONTENTS, MEMBERS, KEY_OBJECTIVES } from './aboutUs.constants';

const AboutUs = ({ url, screenType }) => {
    const { isDesktop, isTab, isMobile } = screenType;

    const renderImageAndName = ({ img, name, title }, index) => { 
        return (
            <div className='flex items-center justify-center'>
                <Card classes={{root: '!w-full !h-full'}}>
                    <CardMedia
                        component="img"
                        height="180"
                        image={img}
                        alt={name}
                        classes={{root: '!h-[20rem]'}}
                    />
                    <CardContent>
                        <p className='text-center text-2xl font-bold'>{name}</p>
                        {title && <p className='text-center text-2xl'>{title}</p>}
                    </CardContent>
                </Card>
            </div>
        )
    }
    
    const renderMembers = () => {
        return (
            <div className="flex flex-col gap-6 p-8">
                <p className='text-center text-5xl my-10'>New Zealand Kannada Koota EC Team for 2022-24</p>
                <div className={cx('grid gap-10', {
                    'grid-cols-6': isDesktop,
                    'grid-cols-4': isTab,
                    'grid-cols-2': isMobile,
                })}>
                    {_map(MEMBERS, renderImageAndName)}
                </div>
            </div>
        )
    }

    const renderKeyObjectives = () => {
        return (
            <div className="flex flex-col gap-6 p-8">
                <p className='mb-5 text-3xl'>The key objectives include but not limited to:</p>
                <ul className='flex flex-col flex-auto gap-6 pb-8'>
                    {_map(KEY_OBJECTIVES, (label) => (
                        <li className='flex gap-3 items-center'>
                            <DoubleArrowIcon fontSize='small' color='action' />
                            <p className='text-3xl'>{label}</p>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    return (
        <div className='flex flex-col'>
            <HeroBanner url={url} screenType={screenType} />
            <ContentWrapper screenType={screenType}>
                <Content list={CONTENTS} screenType={screenType} />
                {renderKeyObjectives()}
                <hr />
                {renderMembers()}
            </ContentWrapper>
        </div>
    )
}

export default AboutUs;