import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    accordionRoot: {
        '&:first-of-type': {
            borderRadius: 'initial !important'
        },
        '&:last-of-type': {
            borderRadius: 'initial !important'
        },
    },
 }));

export default useStyles;