import AboutUs from "../../../pages/AboutUs"
import Events from "../../../pages/Events"
import Home from "../../../pages/Home"
import Membership from "../../../pages/Membership"
import Shaale from "../../../pages/Shaale"

export const NAVIGATION_KEYS = {
    HOME: 'HOME',
    ABOUT_US: 'ABOUT_US',
    MEMBERSHIP: 'MEMBERSHIP',
    EVENTS: 'EVENTS',
    SHAALE: 'SHAALE'
}

export const NAVIGATION_MENU = {
    [NAVIGATION_KEYS.HOME]: { url: '/home', label: 'Home', path: 'home', Component: Home },
    [NAVIGATION_KEYS.ABOUT_US]: { url: '/about-us', label: 'About Us', path: 'about-us', Component: AboutUs },
    [NAVIGATION_KEYS.MEMBERSHIP]: { url: '/membership', label: 'Membership', path: 'membership', Component: Membership },
    [NAVIGATION_KEYS.EVENTS]: { url: '/events', label: 'Events', path: 'events', Component: Events },
    [NAVIGATION_KEYS.SHAALE]: { url: '/kannada-shaale', label: 'Kannada Shaale', path: 'kannada-shaale', Component: Shaale }
}