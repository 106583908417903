import ContentWrapper from '../../components/molecules/ContentWrapper';
import Content from '../../components/molecules/Content';
import HeroBanner from '../../components/molecules/HeroBanner';

import { CONTENTS } from "./shaale.constants";

const Shaale = ({screenType, url}) => {
    
    return (
        <div className='flex flex-col'>
            <HeroBanner url={url} screenType={screenType} />
            <ContentWrapper screenType={screenType}>
                <Content list={CONTENTS} screenType={screenType} />
            
                <div className="flex flex-col gap-6 p-8">
                    <p className='text-2xl'>For enrollment, please contact us <b>@ nzkksecretary@gmail.com</b></p>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Shaale;