import cx from 'classnames';

import CopyrightIcon from '@mui/icons-material/Copyright';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import useStyles from './appFooter.styles';

const AppFooter = ({ screenType }) => {
    const { isMobile } = screenType;
    const classes = useStyles();
    const currentYear = (new Date()).getFullYear();

    const redirectToFacebook = () => {
        window.open('https://www.facebook.com/NZkannadakoota/', '_blank');
    }

    const redirectToInsta = () => {
        window.open('https://www.instagram.com/invites/contact/?i=1o8dixlrkp5m3&utm_content=pyzhztj', '_blank');
    }
    
    return (
        <div className={cx("flex w-full bg-white relative", classes.footer, {
            'flex-col items-start gap-3 p-8': isMobile,
            'items-center justify-between p-8': !isMobile
        })}>
            <div>
                Copyrights
                <CopyrightIcon fontSize='small' color='action' className='ml-1 mr-2' />
                {`${currentYear}. NZ Kannada Koota. All Rights Reserved.`}
            </div>
            <div className='flex gap-3'>
                <FacebookIcon fontSize='large' color="primary" className='cursor-pointer' onClick={redirectToFacebook} />
                <InstagramIcon fontSize='large' color="primary" className='cursor-pointer' onClick={redirectToInsta} />
            </div>
        </div>
    )
}

export default AppFooter;