import cx from 'classnames';

import useStyles from './heroBanner.styles';

const HeroBanner = ({ url, screenType }) => {
    const classes = useStyles();
    const { isMobile } = screenType;
    const { desktop, mobile } = url;
    const backgroundImage = isMobile ? mobile : desktop;
    return (
        <div className={cx('h-[35rem]', classes.banner)} style={{backgroundImage: `url(${backgroundImage})`}}></div>
    )
}

export default HeroBanner;