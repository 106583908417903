import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    headerInner: {
        position: 'fixed',
        top: 0,
        width: '100%',
        left: 0,
        right: 0,
        boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
    },
 }));

export default useStyles;