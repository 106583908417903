export const CONTENTS = [
    {
        label: '೧೯೯೦ರ ದಶಕದ ಮೊದಲು ಕೇವಲ ಬೆರಳೆಣಿಕೆಯಷ್ಟು ಮಾತ್ರ ಇದ್ದ ನ್ಯೂಜಿಲೆಂಡ್ ಕನ್ನಡ ನಿವಾಸಿಗಳ ಸಂಖ್ಯೆ ನಂತರ ಗಮನಾರ್ಹ ಏರಿಕೆ ಕಂಡಿತು. ಆದರೆ ಸದಸ್ಯರು ಅಲ್ಲಲ್ಲಿ ಹಂಚಿಹೋಗಿದ್ದು ಪರಸ್ಪರ ಪರಿಚಯ ಇರಲಿಲ್ಲ. ಆಗ ಕನ್ನಡಿಗರನ್ನು ಒಂದುಗೂಡಿಸುವ ಸದುದ್ದೇಶದಿಂದ ೧೯೯೪ರ ಮಾರ್ಚ್ ತಿಂಗಳಿನಲ್ಲಿ ಒಂದು ಶುಭದಿನ " <b>ಒನ್ ಟ್ರೀ ಹಿಲ್ ನಲ್ಲಿ ಇರುವ ಕಾರ್ನ್ ವಾಲ್ ಪಾರ್ಕ್</b>" ನಲ್ಲಿ ಸಭೆ ಸೇರಿ ಕೂಡಲೇ ಒಂದು ಸಂಸ್ಥೆ ಸ್ಥಾಪಿಸಲು ಸಂಕಲ್ಪ ಮಾಡಿದರು. ಪ್ರಾಚಾರ್ಯ ವಾಮನ ಮೂರ್ತಿ ಮತ್ತು ಡಾ. ಲಿಂಗಪ್ಪ ಕಲ್ಬುರ್ಗಿ ಅವರನ್ನು ಕ್ರಮವಾಗಿ ಸಂಸ್ಥಾಪಕ ಅಧ್ಯಕ್ಷ ಹಾಗೂ ಕಾರ್ಯದರ್ಶಿ ಸ್ಥಾನಕ್ಕೆ ಆಯ್ಕೆ ಮಾಡಲಾಯಿತು. ನಮ್ಮ ಕೂಟ ಒಂದು ಸ್ವಯಂ ಪ್ರೇರಿತ ಸಂಸ್ಥೆಯಾಗಿದ್ದು ಸದಸ್ಯರು ಹಾಗೂ ಸಾರ್ವಜನಿಕರು ನೀಡುವ ಧನಸಹಾಯ ಮತ್ತು ವಾರ್ಷಿಕ ಶುಲ್ಕಗಳ ನೆರವಿನಿಂದ ಕಾರ್ಯ ನಿರ್ವಹಿಸುತ್ತದೆ.',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ಈ ಜಾಲತಾಣ ಕೀವಿ ನಾಡಿನ ಕನ್ನಡಿಗರನ್ನು ಬೆಸೆಯುವ ಒಂದು ವೇದಿಕೆಯಾಗಿದ್ದು ಸಾರ್ವಜನಿಕರೊಡನೆ ಸಮುದಾಯ ಸಂಪರ್ಕ ಕಲ್ಪಿಸಲು ನೆರವಾಗುತ್ತದೆ.‌',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ಈ ಜಾಲತಾಣದ ಧ್ಯೇಯೋದ್ದೇಶಗಳ ಬಗ್ಗೆ. ನಮ್ಮ ಕೂಟದ ಮುಖ್ಯ ಉದ್ದೇಶವೆಂದರೆ, ಅ ಸದಸ್ಯರಲ್ಲಿ ಕನ್ನಡ ಭಾಷಾಭಿಮಾನ, ಪರಸ್ಪರ ಗೌರವ, ಸ್ನೇಹ ಭಾವನೆಗಳು ವೃದ್ಧಿಸುವಂತೆ ಮಾಡುವುದು. ಕೂಟದ ಸಂಸ್ಥಾಪಕ ಅಧ್ಯಕ್ಷ ಪ್ರೊಫೆಸರ್ ವಾಮನ ಮೂರ್ತಿ ಮತ್ತು ಕಾರ್ಯದರ್ಶಿ ಡಾ.ಲಿಂಗಪ್ಪ ಕಲ್ಬುರ್ಗಿ ಇಬ್ಬರ ಮಾತಿನಲ್ಲಿ ಹೇಳುವುದಾದರೆ, ಕನ್ನಡ ಕೂಟ ಕೇವಲ ಒಂದು ಸಂಸ್ಥೆಯಲ್ಲ ಅದು "<b>ನಮ್ಮ ವಿಸ್ತೃತ ಕುಟುಂಬ</b>".',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ಎಲ್ಲಾ ಸದಸ್ಯರಲ್ಲಿ, ವಿಶೇಷವಾಗಿ ಯುವ ಜನರಲ್ಲಿ, ನಮ್ಮ ಭಾಷೆ ನಾಡಿನ ಸಂಸ್ಕೃತಿ, ಸಂಪ್ರದಾಯ, ಆಚಾರ ವಿಚಾರಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸಿ ಅವರ ಜ್ಞಾನಾಭಿವೃದ್ಧಿಗೆ ನೆರವಾಗುವುದು. ಹೊಸದಾಗಿ ನ್ಯೂಜಿಲೆಂಡ್ ದೇಶಕ್ಕೆ ವಲಸೆ ಬರುವ ಕನ್ನಡಿಗರನ್ನು ಆತ್ಮೀಯತೆಯಿಂದ ಸ್ವಾಗತಿಸಿ , ಅವರಿಗೆ ಇಲ್ಲಿನ ಸಂಸ್ಕೃತಿ , ಜನಜೀವನ ಅರಿವಾಗಲು ಸಹಾಯ ಮಾಡಿ ಬದುಕು ಹಸನಾಗಲು ದಾರಿತೋರುವುದು.',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ಸ್ಥಳೀಯ ಶ್ರೀಮಂತ ಸಂಸ್ಕೃತಿಯ ಜೊತೆಗೆ ನಮ್ಮ ಆಚಾರ ವಿಚಾರಗಳು ಸಮ್ಮಿಳಿತಗೊಂಡು ಸದಸ್ಯರ ಜೀವನದ ಉನ್ನತಿಗೆ ಸಹಕಾರ ನೀಡಬೇಕು.',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'A walk down memory lane of NZKK tells us the story of how NZKK came into being. Sometime in March 1994, Prof Vaman Murthy and Dr Lingappa Kalburigi initiated a get together in Cornwall park of One Tree Hill, open to all Kannadigas and thus NZKK was formed. The first president was Prof Vamana Murthy and Dr Lingappa Kalburgi was the first secretary.',
        style: {
            desktop: 'text-3xl',
            mobile: 'text-2xl'
        }
    },
];

export const MEMBERS = [
    {
        img: '../images/members/Ajay_Ravi.JPG',
        name: 'Ajay Ravi',
        title: '(President)'
    },
    {
        img: '../images/members/Naveen.png',
        name: 'Naveen Kumar',
        title: '(Secretary)'
    },
    {
        img: '../images/members/Chandan.png',
        name: 'Chandan Padmanna',
        title: '(Treasury)'
    },
    {
        img: '../images/members/Prakash.png',
        name: 'Prakash Biradar'
    },
    {
        img: '../images/members/Sumati.png',
        name: 'Sumathi Vishwanath'
    },
    {
        img: '../images/members/Aishwarya.jpg',
        name: 'Aishwarya Jayasimha'
    },
    {
        img: '../images/members/Ajay.jpg',
        name: 'Ajay Balaraju'
    },
    {
        img: '../images/members/Karthik.png',
        name: 'Karthik'
    },
    {
        img: '../images/members/Prasad.jpg',
        name: 'Prasad Hegde'
    },
    {
        img: '../images/members/Vidhya.jpg',
        name: 'Vidhya Kandi'
    }
]

export const KEY_OBJECTIVES = [
    'To promote, preserve and maintain the cultural and linguistic heritage of Karnataka.',
    'To promote friendship and respect between members of NZKK and the people of Aotearoa - New Zealand.',
    'To assist new immigrants (especially from Karnataka) to adapt to the new culture and settle down.',
    'To promote and liaise mutual support, joint action and cooperation with other communities and societies in New Zealand.',
    'To provide interested people and organizations with the information about the Karnataka people and culture.',
];