import React from 'react';
import cx from 'classnames';

import Logo from '../../atoms/Logo';
import NavigationMenu from '../../atoms/NavigationMenu';
import HamburgerMenu from '../../atoms/HamburgerMenu';
import SideMenu from '../SideMenu/SideMenu';
import useStyles from './appHeader.styles';

const AppHeader = ({ ...props }) => {
    const { screenType, sideMenuHandler, isSideMenuOpen } = props;
    const { isMobile } = screenType;

    const classes = useStyles();

    return (
            <div className={cx('flex justify-between w-full px-8 py-6 items-center relative bg-white z-10', classes.headerInner)}>
                <Logo {...props} />
                {!isMobile && <NavigationMenu {...props} />}
                {isMobile && <HamburgerMenu setSideMenu={sideMenuHandler} {...props} />}
                <SideMenu onClose={sideMenuHandler} isOpen={isSideMenuOpen} screenType={screenType} />
            </div>
    )
}

export default AppHeader;