export const CONTENTS = [
    {
        label: 'Celebrating important events to showcase the rich culture of Karnataka and India is one of the key focus areas of New Zealand Kannada Koota.',
        style: {
            desktop: 'text-2xl',
            mobile: 'text-2xl'
        }
    },
    {
        label: 'These events provide an opportunity for the koota members to showcase their talents by performing various activities on the stage like singing, dance, dramas etc and through sports skills in an arena. ‌',
        style: {
            desktop: 'text-2xl',
            mobile: 'text-2xl'
        }
    },
];

export const KEY_EVENTS = [
    {
        title: 'Ugadi',
        description: 'The Kannada New Year based on the lunar calendar.'
    },
    {
        title: 'Kannada Rajyotsava',
        description: 'The anniversary celebrations of the State formation day.'
    },
    {
        title: 'Picnic',
        description: 'An outdoor family activity which includes games for all age groups.'
    },
    {
        title: 'Sports Day',
        description: 'Both indoor and outdoor sports to  attract all sport enthusiasts.'
    },
    {
        title: 'Ganapathi Festival',
        description: 'Needs no introduction, also the event when we host our AGM.'
    },
    {
        title: 'Fundraising Events',
        description: 'These are planned at different times of the year to raise funds for the community.'
    }
];

export const SERVICES = [
    {
        title: 'Books and Video Library',
        description: 'NZKK Library and Shaale are often considered the jewel crown. More about Shaale in the Shaale page. The NZKK library is the first Indian Community library which is home to over 5000 Kannada novels and 500 children’s books and comics. You will also fin VCDs and DVDs of the Kannada Classics which are a delight in all times and seasons. Library services are provided throughout the week based on the community’s needs. The library premises also often serve as a meeting room for other communities, based on the inclusive principles on which this community was founded.'
    },
    {
        title: 'Cultural Activities',
        description: 'Cultural Events are organised by the Koota on most of the events mentioned above. The nature of these events is to include everyone and provide a platform to showcase individual talents. Apart from the cultural programs at the Koota events, NZKK also actively participates in the events organised by the Auckland Indian Association, Federation of South Indian Associations New Zealand, Auckland City Council and the Auckland Diwali Mela to name a few.'
    },
    {
        title: 'Sports Day',
        description: 'Sports Day is usually conducted during July/August every year and closes with an exciting grand finale. Badminton, Table Tennis, Carrom and Chess are some of the front runners.'
    },
    {
        title: 'Annual Picnic',
        description: 'The picnic is usually held in February, in the last few weeks of Summer. It is common to see barbecues and sumptuous lunches alongside picnic mats and outdoor games. One of the recent picnics had a bouncy castle, which was a treat for all the children!'
    },
    {
        title: 'Fundraising events',
        description: 'The Koota organises fund raising camps, to raise funds for the community and other organizations as well. The most popular fundraising event has been “Dosa Camp”,  all run by volunteers has some of the best South Indian delicacies on sale. During sports day, NZKK distributes lunch packs for donations, and these donations are handed over to The Cancer Society of New Zealand every year.'
    },
    {
        title: 'Workshops',
        description: 'Professionals from various segments are invited to conduct seminars, workshops and training to benefit NZKK members and families. These forums are of great benefit to students, new immigrants and families in need of support and guidance in many areas especially: first home buyers, education guidance, career progression, property investment and development, financial stability, community service, network and personality development.'
    }
];