import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    banner: {
        backgroundSize: 'cover',
        backgroundPosition: 'cemter',
        backgroundRepeat: 'no-repeat',
    },
 }));

export default useStyles;