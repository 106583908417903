import _map from 'lodash/map';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ContentWrapper from '../../components/molecules/ContentWrapper';
import HeroBanner from '../../components/molecules/HeroBanner';
import Content from '../../components/molecules/Content';

import { CONTENTS, KEY_EVENTS, SERVICES } from './events.constants';
import useStyles from './events.styles';

const Events = ({ url, screenType }) => {
    const classes = useStyles();
    return (
        <div className='flex flex-col'>
            <HeroBanner url={url} screenType={screenType} />
            <ContentWrapper screenType={screenType}>
                <Content list={CONTENTS} screenType={screenType} />

                <div className="flex flex-col gap-6 px-8 pb-8">
                    <p className='mb-5 text-2xl'>The events which we conduct on an annual basis include but are not limited:</p>
                    <ul className='flex flex-col flex-auto gap-6 pb-8'>
                        {_map(KEY_EVENTS, ({title, description}) => (
                            <li className='flex gap-3 items-center'>
                                <DoubleArrowIcon fontSize='small' color='action' />
                                <p className='text-2xl'><b>{title}: </b>{description}</p>
                            </li>
                        ))}
                    </ul>

                    <p className='mb-5 text-4xl'>Services:</p>
                    <div>
                        {_map(SERVICES, ({title, description}) => (
                                <Accordion classes={{root: classes.accordionRoot}}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <p className='text-2xl font-bold'>{title}</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>{description}</p>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                    </div>
                </div>
             </ContentWrapper>
        </div>
    )
}

export default Events;