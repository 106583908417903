import React from 'react';

import { Link } from "react-router-dom";

const Logo = () => {
    return (
        <Link to='/home' className='cursor-pointer'>
            <img className='max-w-[7rem]' src='../images/logo.jpg' alt='Logo' />
        </Link>
    )
}

export default Logo;