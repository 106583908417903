import { useTheme } from '@mui/material/styles';
import useMediaQuery from '../hooks/useMediaQuery';

const withSizes = (Component) => ({ ...props }) => {
  const theme = useTheme();
  const md = theme.breakpoints.values.md;
  const lg = theme.breakpoints.values.lg;

    const _isTab = useMediaQuery(`(min-width: ${md}px)`);
    const isDesktop = useMediaQuery(`(min-width: ${lg}px)`);
    const isTab = _isTab && !isDesktop;
    const isMobile = !(isTab || isDesktop);

    const screenType = {
      isDesktop,
      isMobile,
      isTab,
    };
  
    return <Component {...props} screenType={screenType} />;
}

export default withSizes;
