import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';

import NavigationMenu from "../../atoms/NavigationMenu";

const SideMenu = ({ onClose, isOpen, screenType }) => {
    return (
        <Drawer
            anchor='right'
            open={isOpen}
            onClose={onClose}
            classes={{paper: 'w-3/4 p-8 pt-40'}}
          >
            <CloseIcon onClick={onClose} className="absolute top-6 right-6 left-auto" color='action' />
            <NavigationMenu customClass='flex-col w-full text-right' closeNavigationDrawer={onClose} screenType={screenType} />
          </Drawer>
    );
}

export default SideMenu;