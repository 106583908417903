import React from 'react';

import _map from 'lodash/map';

import MenuLink from '../MenuLink';

import { NAVIGATION_MENU } from './navigation.constants';

const NavigationMenu = ({ customClass, closeNavigationDrawer, screenType }) => {

    const renderLink = ({ url, label }) => {
        return (<MenuLink to={url} label={label} onClick={closeNavigationDrawer} screenType={screenType} />)
    }

    return (
        <nav className={`flex gap-10 ${customClass}`}>
            {_map(NAVIGATION_MENU, renderLink)} 
        </nav>
    )
}

export default NavigationMenu;