import React from "react";
import { Link, useMatch } from "react-router-dom";
import cx from 'classnames';

import useStyles from './menuLink.styles';

const MenuLink = ({ to, label, onClick, screenType }) => {
    const classes = useStyles();
    let { pathname } = useMatch(to) || {};
    const match = pathname === to;
    const { isMobile } = screenType;

    const onClickHandler = () => {
        if (!isMobile) return;
        onClick();
    }

    return (<Link to={to} key={label} onClick={onClickHandler} className={cx({[classes.matchedLink]: match})}>{label}</Link>);
}

export default MenuLink;