import _map from 'lodash/map';

import { checkIfStringIsHtml } from '../../../helpers/browser';

const Content = ({ list, screenType }) => {
    const { isMobile } = screenType;

    const renderLabel = (label) => {
        const isHtml = checkIfStringIsHtml(label);

        if (isHtml) return (<div dangerouslySetInnerHTML={{ __html: label }} />)
        return (<>{ label }</>)
    }

    const renderHeadingAndDescription = ({ label, style }) => {
        const { desktop, mobile } = style;
        return (
            <div>
                {label && <p className={isMobile ? mobile : desktop}>{renderLabel(label)}</p>}
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-6 p-8">
            {_map(list, renderHeadingAndDescription)}
        </div>
    )
}

export default Content;