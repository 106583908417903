export const CONTENTS = [
    {
        label: 'ಸಮಸ್ತ ಕನ್ನಡ ಕುಲಬಾಂಧವರಿಗೆ ಹೃತ್ಪೂರ್ವಕ ನಮನಗಳು ಮತ್ತು ನ್ಯೂಜಿಲೆಂಡ್ ಕನ್ನಡ ಕೂಟಕ್ಕೆ ಆತ್ಮೀಯ ಸ್ವಾಗತ.',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ಕರ್ನಾಟಕ ದಕ್ಷಿಣ ಭಾರತದ ಒಂದು ರಾಜ್ಯ, ಅಲ್ಲಿನ ಜನರ ಮಾತೃಭಾಷೆ ಹಾಗೂ ನಾಡಿನ ಆಡಳಿತ ಭಾಷೆ ಕನ್ನಡ. ನ್ಯೂಜಿಲೆಂಡ್  ನಿವಾಸಿ ಕನ್ನಡ ಭಾಷಿಗರು ತಮಗಾಗಿ ತಾವೇ ಕಟ್ಟಿಕೊಂಡ  ಒಂದು ಪುಟ್ಟ ಸುಸಂಸ್ಕೃತ ಸಂಸ್ಥೆ "<b>ನ್ಯೂಜಿಲೆಂಡ್ ಕನ್ನಡ ಕೂಟ</b>". ಇದು ಸದಸ್ಯರಲ್ಲಿ "<b>ನಮ್ಮ ಕೂಟ</b>" ಎಂದೇ ಜನಪ್ರಿಯವಾಗಿದೆ.‌ ',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'ನಮ್ಮ ಕೂಟ ಒಂದು ಲಾಭರಹಿತ, ಸಾಮಾಜಿಕ ಕಳಕಳಿಯುಳ್ಳ , ಭಾಷಾಧಾರಿತ, ಸಾಂಸ್ಕೃತಿಕ ಸಂಸ್ಥೆಯಾಗಿದ್ದು ಇದರ ಮೂಲ ಉದ್ದೇಶ ನ್ಯೂಜಿಲೆಂಡ್ ನಿವಾಸಿ ಕನ್ನಡಿಗರಿಗೆ ಒಂದು ವೇದಿಕೆ ನಿರ್ಮಾಣ ಮಾಡಿ, ಅವರಲ್ಲಿ ದೇಶಾಭಿಮಾನ ಭಾಷಾಭಿಮಾನ ಜಾಗ್ರತಗೊಳಿಸುವುದು ಹಾಗೂ ಈ ನಿಟ್ಟಿನಲ್ಲಿ ಅಗತ್ಯವಾದ ಚಟುವಟಿಕೆಗಳು ನಿರಂತರವಾಗಿ ಯಶಸ್ವಿಯಾಗಿ ನಡೆಯಲು ಬೆಂಬಲ ನೀಡುವುದು. ಕನ್ನಡ ಭಾಷೆ ಮತ್ತು ಸಂಸ್ಕೃತಿಯನ್ನು ಉಳಿಸಿ ಬೆಳೆಸಲು ಸದಸ್ಯರನ್ನು ಒಗ್ಗೂಡಿಸಿ, ಪ್ರೇರಣೆ ನೀಡಿ, "<b>ನಮ್ಮ ಭಾಷೆಯೊಂದೇ ಭಾವವೂ ಒಂದೇ</b>" ಎನ್ನುವಂತೆ ಏಕತೆ ಮೂಡಿಸುವುದು.',
        style: {
            desktop: 'text-3xl leading-12',
            mobile: 'text-2xl leading-12'
        }
    },
    {
        label: 'The New Zealand Kannada Koota  (NZKK) more commonly referred to as “Namma Koota” is a community organization conceptualized and built by Kannadigas residing in New Zealand.',
        style: {
            desktop: 'text-3xl',
            mobile: 'text-2xl'
        }
    },
    {
        label: 'NZKK is a non profit, linguistic and cultural community group. The primary goal of NZKK is to provide a platform for Kannadigas in New Zealand, to nurture feelings of pride in our roots while integrating in New Zealand culture and society at large.',
        style: {
            desktop: 'text-3xl',
            mobile: 'text-2xl'
        }
    },
];

export const QUOTES = [
    {
        quote: '" ಎಲ್ಲಾದರೂ ಇರು ಎಂತಾದರು ಎಂದೆಂದಿಗೂ ನೀ ಕನ್ನಡವಾಗಿರು "',
        poet: 'ಕು.ವೆಂ.ಪು.'
    },
    {
        quote: '" ಜಗದೇಳಿಗೆ ಆಗಲಿದೆ ಕರ್ನಾಟಕದಿಂದ "',
        poet: 'ದ.ರಾ.ಬೇಂದ್ರೆ.'
    }
]