import cx from 'classnames';

import ContentWrapper from '../../components/molecules/ContentWrapper';
import HeroBanner from '../../components/molecules/HeroBanner';

import { FIRST_PARA, SECOND_PARA } from "./membership.constants";
import useStyles from './membership.styles';

const Membership = ({ screenType, url }) => {
    const classes = useStyles();
    const { isMobile } = screenType;

    const renderMembershipSection = () => {
        return (
            <div className={cx('relative', {
                // 'max-w-[35%]': !isMobile,
                // 'border-b-2 pb-8': isMobile
            })}>
                <div className={cx("flex flex-col gap-6", {
                    [cx('p-10 z-0', classes.rightSection)]: !isMobile,
                })}>
                    <div>
                        <b>Individual Membership</b>
                        <br />
                        <p className='text-3xl'>$ 45</p>
                    </div>
                        
                    <div>
                        <b>Family Membership</b>
                        <br />
                        <p className='text-3xl'>$ 90</p>
                    </div>
                        
                    <div>
                        Dependent children are covered by family membership
                        <br />
                        <br />
                        No Membership fees for International students
                    </div>
                        
                </div>
            </div>
        )
    }

    const renderAccountInfo = () => {
        return (
            <div className={cx("flex flex-col gap-6", {
                    [cx('p-10 z-0', classes.rightSection)]: !isMobile,
            })}>
                <div>Bank details to send your membership:</div>
                <div className='font-bold'>
                    New Zealand Kannada Koota Inc.
                    <br />
                    ASB Bank A/C No: 12-3048-0305974-00
                </div>
            </div>
        )
    }
    
    return (
        <div className='flex flex-col'>
            <HeroBanner url={url} screenType={screenType} />
            
            <ContentWrapper screenType={screenType}>
                <div className={cx("flex gap-6 p-8 justify-between", {
                'flex-col-reverse': isMobile
                })}>
                    <div className={cx("flex flex-col gap-6", {
                        'w-[55%]': !isMobile
                    })}>
                        <p className='text-2xl'>{FIRST_PARA}</p>
                        <p className='text-2xl'>{SECOND_PARA}</p>
                        <p className='text-2xl'>To register, please send your details to <b>nzkksecretary@gmail.com</b></p>
                    </div>
                    <div className={cx('flex gap-4 flex-col', {
                        'max-w-[35%]': !isMobile,
                        'border-b-2 pb-8': isMobile
                    })}>
                        {renderMembershipSection()}
                        {renderAccountInfo()}
                    </div>
                
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Membership;