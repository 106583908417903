export const CONTENTS = [
    {
        label: 'Did you know that Kannada language is one of the oldest spoken languages of India? It is important that we encourage the younger generation to learn their mother tongue, not only so they can converse with multiple people, but also so that they remember their roots and ancestors. This belief in us has lead us to organize a weekly Kannada Shaale (School) to help spread the amazing knowledge of Kannada. At our Shaale, students are taught in multiple age groups, so that they receive the right level of course work and support.',
        style: {
            desktop: 'text-2xl',
            mobile: 'text-2xl'
        }
    },
    {
        label: 'A group of dedicated and kind volunteers from our very own NZKK community teach these classes. They teach not only how to speak Kannada, but also to read and write it. At the end of the class, some senior members of the community share their experiences to encourage and help the younger students. This includes storytelling (meaningful and moral stories), poetry recital competitions, annual day events, etc. The children are also taught and encouraged to participate in Dance, Music, and Creative Arts events.',
        style: {
            desktop: 'text-2xl',
            mobile: 'text-2xl'
        }
    },
];