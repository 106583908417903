import { makeStyles } from '@material-ui/core/styles';

const headerHeight = '10rem';

const useStyles = makeStyles((theme) => ({
    body: {
        minHeight: `calc(100vh - ${headerHeight})`,
    },
 }));

export default useStyles;