import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div>
            <div className="flex flex-col items-center justify-between gap-6 p-8">
                <div className="max-w-sm">
                    <img src='../images/logo.jpg' alt='Logo' />
                </div>
                <p className="text-7xl">
                    Page Not Found
                </p>
                <Link to='/home' className='cursor-pointer'>
                    Go to Home Page
                </Link>
            </div>
        </div>
    )
}

export default PageNotFound;