import { makeStyles } from '@material-ui/core/styles';

const headerHeight = '10rem';

const useStyles = makeStyles((theme) => ({
    mainContent: {
        paddingTop: headerHeight,
    },
 }));

export default useStyles;