import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rightSection: {
        position: 'sticky',
        top: 0,
        width: '100%',
        left: 0,
        right: 0,
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
 }));

export default useStyles;