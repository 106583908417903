import cx from 'classnames';

const ContentWrapper = ({ children, screenType }) => {
    const { isDesktop } = screenType;

    return (
        <div className={cx({'max-w-[98rem] m-auto': isDesktop})}>
            {children}
        </div>
    )
}

export default ContentWrapper;