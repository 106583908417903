import { Routes, Route } from "react-router-dom";

import _map from 'lodash/map';

import { NAVIGATION_KEYS, NAVIGATION_MENU } from "../NavigationMenu/navigation.constants";

import Home from "../../../pages/Home";
import PageNotFound from "../../../pages/PageNotFound";

import { HERO_BANNER_FOR_PAGES } from '../../../components/molecules/HeroBanner/heroBanner.constants';

const RouteToPage = (props) => (
    <Routes>
         <Route exact path="/" element={<Home url={HERO_BANNER_FOR_PAGES[NAVIGATION_KEYS.HOME]} {...props} />} />
        {_map(NAVIGATION_MENU, ({ path, Component }, key) => (<Route path={path} element={<Component url={HERO_BANNER_FOR_PAGES[key]} {...props} />} key={path} />))}
        <Route path="*" element={<PageNotFound {...props} />} />
    </Routes>
)

export default RouteToPage;