import cx from 'classnames';

import _map from 'lodash/map';

import ContentWrapper from '../../components/molecules/ContentWrapper';
import Content from '../../components/molecules/Content';
import HeroBanner from '../../components/molecules/HeroBanner';
import { CONTENTS, QUOTES } from './home.constants';

const Home = ({ screenType, url }) => {
    const { isMobile } = screenType;
    const renderQuotes = () => {
        return (
            <div className={cx("flex flex-col gap-10 p-8")}>
                {_map(QUOTES, ({quote, poet}) => (
                    <div className={cx({'flex flex-col w-max gap-3': !isMobile})}>
                        <p className='text-5xl text-gray-500'>{quote}</p>
                        <p className='font-bold text-3xl text-right'>{poet}</p>
                    </div>
                ))}
            </div>
        )
    }

    return (
            <div className='flex flex-col'>
                <HeroBanner url={url} screenType={screenType} />
                <ContentWrapper screenType={screenType}>
                    {/* {renderQuotes()}   */}
                    <Content list={CONTENTS} screenType={screenType} />
                </ContentWrapper>
            </div>
    )
}

export default Home;