import React, { useState } from 'react';

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppHeader from './components/molecules/AppHeader';
import AppBody from './components/molecules/AppBody';
import AppFooter from './components/molecules/AppFooter';
import RouteToPage from './components/atoms/RouteToPage';

import withSizes from './hocs/withSizes'

import { defaultTheme } from "./styles/createTheme";
import useStyles from './app.styles';

function App(props) {
  const [isSideMenuOpen, setSideMenu] = useState(false);
  const classes = useStyles();

  const handleSideMenu = () => {
    setSideMenu(!isSideMenuOpen);
  }
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <BrowserRouter>
        <AppHeader isSideMenuOpen={isSideMenuOpen} sideMenuHandler={handleSideMenu} {...props} />
        <div className={classes.mainContent}>
          <AppBody isSideMenuOpen={isSideMenuOpen} {...props}>
            <RouteToPage {...props} />
          </AppBody>
          <AppFooter isSideMenuOpen={isSideMenuOpen} {...props} />
        </div>
        
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withSizes(App);
