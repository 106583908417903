import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';

const HamburgerMenu = ({setSideMenu}) => {
    return (
        <div className='' onClick={setSideMenu} tabIndex={0} role='button'>
            <MenuIcon color="action" />
        </div>
    )
}

export default HamburgerMenu;